import get from 'lodash/get';

import { addError } from '@src/validations';

import { source } from './data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.firstName)) {
        addError(errors, source.firstName, 'First Name is required');
    }

    if (!get(values, source.lastName)) {
        addError(errors, source.lastName, 'Last Name is required');
    }

    if (!get(values, source.email)) {
        addError(errors, source.email, 'Email is required');
    }

    if (!get(values, source.address)) {
        addError(errors, source.address, 'Address is required');
    }

    if (!get(values, source.city)) {
        addError(errors, source.city, 'City is required');
    }

    if (!get(values, source.stateId)) {
        addError(errors, source.stateId, 'State is required');
    }

    if (!get(values, source.zipCode)) {
        addError(errors, source.zipCode, 'Zip Code is required');
    }

    if (!get(values, source.agencyFirstName)) {
        addError(errors, source.agencyFirstName, 'Agency First Name is required');
    }

    if (!get(values, source.agencyLastName)) {
        addError(errors, source.agencyLastName, 'Agency Last Name is required');
    }

    if (!get(values, source.agencyEmail)) {
        addError(errors, source.agencyEmail, 'Agency Email is required');
    }

    if (!get(values, source.agencyPhoneNumber)) {
        addError(errors, source.agencyPhoneNumber, 'Agency Phone Number is required');
    }

    if (!get(values, source.agencyWebsite)) {
        addError(errors, source.agencyWebsite, 'Agency Website is required');
    }

    if (!get(values, source.agencyName)) {
        addError(errors, source.agencyName, 'Agency Name is required');
    }

    if (!get(values, source.status)) {
        addError(errors, source.status, 'Status is required');
    }

    return errors;
};


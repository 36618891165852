import React from 'react';

import { SimpleForm } from 'react-admin';

import { validate } from './validate';

import { EditActions, MaxWidthEdit, EditWithNewVersionToolbar } from '@src/custom';
import Form from './form';

const EditCollateralRequest = (props) => {
    return (
        <MaxWidthEdit
            title="Edit Collateral Request"
            actions={<EditActions hasClone={false} />}
            {...props}
        >
            <SimpleForm
                redirect="edit"
                submitOnEnter={false}
                validate={validate}
                toolbar={<EditWithNewVersionToolbar />}
            >
                <Form {...props} />
            </SimpleForm>
        </MaxWidthEdit>
    );
};

export default EditCollateralRequest;
